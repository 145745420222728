import httpClient from './http-clients/portaljad'

const baseUrl = 'integracao'

export default class IntegracaoService {
  static async findAll() {
    try {
      const { data } = await httpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findVendas(filtros) {
    try {
      const { data } = await httpClient.post(baseUrl, filtros)
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async compleatePagination(formData) {
    try {
      const { data } = await httpClient.post([baseUrl, 'pagination'].join('/'), formData)
      return data
    } catch (error) {
      throw error.response.data
    }
  }
}