<template>
  <div>
    <tratativa-sidebar
      :active.sync="showTratativaSidebar"
      @aoSalvar="atualizarPagina"
    />
    <cte-tracking-sidebar
      :active.sync="showCteTrackingSidebar"
      @aoFechar="limpaCte"
    />
    <b-card
      no-body
      class="mb-2"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="validationSearch"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-card-header class="p-2">
            <b-card-title class="font-medium-2 card-title">
              <feather-icon
                icon="SearchIcon"
                size="18"
              />
              <span class="align-middle ml-50">Tratativa de Entregas</span>
            </b-card-title>
          </b-card-header>
          <hr class="my-0">
          <div class="p-2">
            <b-row>
              <b-col
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  v-model="dateRange"
                  name="date-range"
                >
                  <date-range :date-range.sync="dateRange"/>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  name="Shippment Id"
                >
                  <b-form-group
                    label="Shippment Id"
                    label-for="shippmentId"
                  >
                    <b-form-input
                      id="shippmentId"
                      v-model="filtros.shippmentId"
                      :state="getValidationState(validationContext)"
                      placeholder="Shippment Id"
                    />
                  </b-form-group>
                  <span class="undermessage-error">
                    {{ validationContext.errors[ 0 ] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  name="CTE"
                >
                  <b-form-group
                    label="CTE"
                    label-for="CTE"
                  >
                    <b-form-input
                      id="CTE"
                      v-model="filtros.cte"
                      :state="getValidationState(validationContext)"
                      placeholder="CTE"
                    />
                  </b-form-group>
                  <span class="undermessage-error">
                    {{ validationContext.errors[ 0 ] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  rules="noAlpha"
                  name="Nota Fiscal"
                >
                  <b-form-group
                    label="Nota Fiscal"
                    label-for="notaFiscal"
                  >
                    <b-form-input
                      id="notaFiscal"
                      v-model="filtros.numeroNotaFiscal"
                      :state="getValidationState(validationContext)"
                      placeholder="Nota Fiscal"
                    />
                  </b-form-group>
                  <span class="undermessage-error">
                    {{ validationContext.errors[ 0 ] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Status da Ocorrência"
                  label-for="statusOcorrencia"
                >
                  <v-select
                    id="statusOcorrencia"
                    v-model="filtros.statusTratativa"
                    multiple
                    :options="statusTratativaFinal"
                    :reduce="val=>val.value"
                    :clearable="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="2"
              >
                <div>
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mb-sm-0 mr-0 mr-sm-1"
                  >
                    Buscar
                    <feather-icon
                      class="ml-1 d-md-none d-xl-inline d-inline"
                      icon="SearchIcon"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card
      no-body
      class="mb-2"
    >
      <template v-if="showResultsTable">
        <b-card-header class="p-2">
          <b-card-title class="font-medium-2 card-title">
            <feather-icon
              icon="SearchIcon"
              size="18"
            />
            <span class="align-middle ml-50"> Resultados </span>
          </b-card-title>
          <b-col
            md="3"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              class="px-3"
              @click="gerarExcel"
            >
              <span class="text-nowrap">Exportar Excel</span>
            </b-button>
          </b-col>
        </b-card-header>
        <hr class="my-0">
        <div class="m-2">
          <b-row>
            <!-- Search -->
            <b-col
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="search"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
                  debounce="500"
                />
              </div>
            </b-col>
            <!-- Per Page -->
            <b-col
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrando</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>tratativas por página</label>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="tableRef"
          responsive
          class="position-relative"
          :items="tratativas"
          :fields="resultsTableColumns"
          :filter="search"
          :per-page="perPage"
          :current-page="currentPage"
          show-empty
          stacked="md"
          :empty-text="carregando ? 'Carregando...' : 'Nenhum resultado encontrado.'"
          empty-filtered-text="Nenhum resultado encontrado com os filtros informados."
          @filtered="onFiltered"
        >
          <template #cell(dataEnvio)="data">
            <div>
              {{ data.item.dataEnvio ? maskDate(data.item.dataEnvio) : '' }}
            </div>
          </template>
          <template #cell(status)="data">
            <div>
              {{ ajusteStatus(data.item.status) }}
            </div>
          </template>
          <template #cell(detalhes)="data">
            <b-button
              variant="link"
              @click="openModal(data)"
            >
              <feather-icon
                icon="EyeIcon"
                size="18"
              />
            </b-button>
          </template>
          <template #cell(cte)="data">
            <div
              class="cte-link"
              @click="abrirCteTracking(data.item.cte)"
            >
              {{ data.item.cte }}
            </div>
          </template>
          <template 
            #cell(acoes)="data"
          >
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item 
                @click="adicionarTarifa(data)"
              >
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">Abrir Tratativa</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <hr class="mt-0 mb-1">
        <div class="m-2">
          <b-row v-if="totalRows > 0">
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Mostrando do 
                {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
                {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                  totalRows
                }} resultados</span>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
    <b-modal
      ref="modal"
      title="Detalhes"
      ok-only
      ok-title="OK"
      size="xl"
      style="max-height: 1000px"
    >
      <datails-tratativas :data="modalData" />
    </b-modal>
  </div>
</template>

<script>
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import moment from 'moment'
import utils from '@/helpers/utils'
import ExcelComposition from '@/components/exportar/excel.composition'
import swal from 'sweetalert2/dist/sweetalert2.min'
import store from '@/store'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import DateRange from '@/components/form/DateRange.vue'
import { $themeConfig } from '@themeConfig'
import TratativaSidebar from '../components/tratativa-sidebar'
import DatailsTratativas from '../components/details-tratativa.vue'
import CteTrackingSidebar from '../components/cte-tracking-sidebar/index.vue'

export default {
  name: 'IndexVue',
  components: { TratativaSidebar, DatailsTratativas, DateRange, CteTrackingSidebar },
  setup() {
    const { appCarregando } = $themeConfig.app
    const movimentacaoEventoStore = createNamespacedHelpers('movimentacaoEvento')

    const { setCte } = movimentacaoEventoStore.useActions(['setCte'])
    const { setDataEnvio } = movimentacaoEventoStore.useActions(['setDataEnvio'])

    const statusTratativaFinal = [
      { value: 'PENDENTE', label: 'Pendente' },
      { value: 'PENDENTE_CLIENTE', label: 'Pendente Cliente' },
      { value: 'PENDENTE_JADLOG', label: 'Pendente Jadlog' },
      { value: 'FINALIZADO', label: 'Finalizado' },
    ]

    const tratativaStore = createNamespacedHelpers('tratativa')
    const { setSelected } = tratativaStore.useActions(['setSelected'])

    const modal = ref(null)
    const tableRef = ref(null)

    const { clienteId } = JSON.parse(localStorage.getItem('userName'))

    const state = reactive({
      dateRange: {
        startDate: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        endDate: new Date().toISOString().split('T')[0],
      },
      filtros: {
        shippmentId: null,
        cte: null,
        numeroNotaFiscal: null,
        statusTratativa: ['PENDENTE', 'PENDENTE_CLIENTE', 'PENDENTE_JADLOG'],
        clienteId,
      },
      showTratativaSidebar: false,
      showCteTrackingSidebar: false,
      tratativas: tratativaStore.useGetters(['tratativa']).tratativa,
      showResultsTable: false,
      validationSearch: null,
      modalData: {},
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      carregando: false,
    })

    watch(() => state.carregando, value => {
      if (value) {
        appCarregando.show()
      } else {
        appCarregando.hide()
      }
    })

    watch(() => state.tratativas, value => {
      if (value && value.length) {
        state.totalRows = value.length
      } else {
        state.totalRows = 0
      }
    })

    const reset = () => {
      state.filtros = {
        shippmentId: null,
        cte: null,
        numeroNotaFiscal: null,
        statusTratativa: [],
        dataInicial: new Date().toISOString().split('T')[0], 
        dataFinal: new Date().toISOString().split('T')[0],
        clienteId,
      }
    }
    const sendRequest = filtros => {
      state.carregando = true
      store.dispatch('tratativa/findAllEntregas', filtros).then(() => {
        state.carregando = false
        state.showTratativaSidebar = false
        state.showResultsTable = true
      })
    }

    const search = ref('')

    const onSubmit = () => {
      state.currentPage = 1
      search.value = ''
      let statusTratativa = []
      const stateStatusLength = state.filtros.statusTratativa.length
      if (stateStatusLength === 0) {
        statusTratativa = ['PENDENTE', 'PENDENTE_CLIENTE', 'PENDENTE_JADLOG']
        state.filtros.statusTratativa = []
      } else {
        statusTratativa = state.filtros.statusTratativa
      }
      const filtros = {
        ...state.filtros,
        statusTratativa,
        dataInicial: state.dateRange.startDate,
        dataFinal: state.dateRange.endDate,
      }
      sendRequest(filtros)
    }

    const resultsTableColumns = [
      { key: 'dataEnvio', sortable: true, label: 'Data Envio', tdClass: 'text-nowrap', thStyle: { whiteSpace: 'nowrap' } },
      { key: 'shippmentId', sortable: true, thStyle: { whiteSpace: 'nowrap' } },
      { key: 'cte', sortable: true },
      { key: 'numeroNotaFiscal', sortable: true, label: 'NF', tdClass: 'text-nowrap' },
      { key: 'nomeDestinatario', sortable: true, label: 'Destinatário' },
      { key: 'ultimoEvento', sortable: true, label: 'Eventos' },
      { key: 'status', sortable: true, label: 'Status da Ocorrência', tdClass: 'text-nowrap' },
      { key: 'detalhes', sortable: false, label: 'Detalhes' },
      { key: 'acoes', sortable: false, label: 'Ações' },
    ]

    const openModal = data => {
      state.modalData = data.item
      modal.value.show()
    }

    const atualizarPagina = () => {
      onSubmit()
    }

    const ajusteStatus = data => {
      const dictionary = {
        FINALIZADO: 'Finalizado',
        PENDENTE: 'Pendente',
        PENDENTE_CLIENTE: 'Pendente Cliente',
        PENDENTE_JADLOG: 'Pendente Jadlog',
        DEFAULT: '',
      }
      return dictionary[data] ? dictionary[data] : dictionary.DEFAULT
    }

    const adicionarTarifa = data => {
      state.showTratativaSidebar = true
      setSelected(data.item)
    }

    const { downloadSpreadsheet } = ExcelComposition()
    const downloadExcel = () => {
      const dados = tableRef.value.sortedItems
      const newData = []
      // eslint-disable-next-line consistent-return
      dados.forEach(dado => {
        if (dado.tratativaEntregaDescricaoDTO.length > 0) {
          dado.tratativaEntregaDescricaoDTO.forEach(item => {
            const newHistory = {
              shippmentId: dado.shippmentId,
              cte: dado.cte,
              dataEnvio: utils.maskDate(dado.dataEnvio),
              nomeDestinatario: dado.nomeDestinatario,
              destinatarioTipoDocumento: dado.destinatarioTipoDocumento,
              destinatarioNumeroDocumento: dado.destinatarioNumeroDocumento,
              cep: dado.cep,
              cidade: dado.cidade,
              bairro: dado.bairro,
              uf: dado.uf,
              endereco: dado.endereco,
              numero: dado.numero,
              complemento: dado.complemento,
              email: dado.email,
              celular: dado.celular,
              pesoTotalAferido: dado.pesoTotalAferido,
              quantidade: dado.quantidade,
              situacaoTracking: dado.situacaoTracking,
              ultimoEvento: dado.ultimoEvento,
              dataUltimoEvento: dado.dataUltimoEvento ? utils.maskDateHour(dado.dataUltimoEvento) : '',
              nomeServico: dado.nomeServico,
              numeroNotaFiscal: dado.numeroNotaFiscal,
              valorNotaFiscal: dado.valorNotaFiscal,
              valorDeclaracaoConteudo: dado.valorDeclaracaoConteudo,
              valorParaSeguro: dado.valorParaSeguro,
              status: dado.status,
              atendimentoId: item.atendimentoId,
              dataGeracao: utils.maskDateHour(item.dataGeracao),
              descricao: item.descricao,
              acareacao: item.acareacao ? 'Sim' : 'Não',
            }
            newData.push(newHistory)
          })
          return dado
        }
      })
      if (newData.length === 0) {
        appCarregando.hide()
        swal.fire({
          icon: 'warning',
          title: 'Sem dados para exportar',
          text: 'O Resultado da sua busca não retornou dados para exportação.',
          showConfirmButton: false,
          timer: 3000,
        })
      } else {
        downloadSpreadsheet(newData, 'Tratativas')
      }
    }

    const gerarExcel = () => {
      appCarregando.show()
      setTimeout(() => {
        downloadExcel()
      }, 100)
    }

    const abrirCteTracking = data => {
      setCte(data)
      setDataEnvio(state.dateRange.startDate)
      state.showCteTrackingSidebar = true
    }

    const { getValidationState, resetForm } = formValidation(reset)

    const onFiltered = filteredItems => {
      state.currentPage = 1
      state.totalRows = filteredItems.length
    }

    return {
      ...toRefs(state),
      ...utils,
      statusTratativaFinal,
      resultsTableColumns,
      search,
      getValidationState,
      adicionarTarifa,
      atualizarPagina,
      ajusteStatus,
      sendRequest,
      abrirCteTracking,
      onSubmit,
      openModal,
      resetForm,
      gerarExcel,
      modal,
      tableRef,
      onFiltered,
    }
  },
}
</script>
<style scoped>
  .form-group {
    min-width: 100%;
  }
  .table-footer {
    font-weight: bold;
    background: #E9E9E9;
    border-bottom: 3px solid #F55559;
    font-size: 1.2rem;
  }
  .widerTh {
    min-width: 30rem;
    margin: 0 30px;
  }
  .cte-link {
    color: #263bdb;
    cursor: pointer;
  }
</style>