<template>
  <div>
    <b-card
      no-body
      class="mb-2"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="validationSearch"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <div class="p-2">
            <b-row>
              <b-col
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  v-model="dateRange"
                  name="date-range"
                >
                  <date-range :date-range.sync="dateRange"/>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                v-if="mostrarDepartamento"
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="departamento"
                  :rules="{ required: false }"
                >
                  <b-form-group
                    label="Departamento"
                    label-for="departamento"
                  >
                    <v-select
                      id="departamento"
                      v-model="filtros.clienteDepartamentoId"
                      multiple
                      :options="selectDepartamentos"
                      :reduce="val=>val.value"
                      :clearable="true"
                      placeholder="TODOS"
                    />
                  </b-form-group>
                  <span class="undermessage-error">
                    {{ validationContext.errors[ 0 ] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Serviço"
                  :rules="{ required: false }"
                >
                  <b-form-group
                    label="Serviço"
                    label-for="servico"
                  >
                    <v-select
                      id="servico"
                      v-model="filtros.servico"
                      multiple
                      :options="selectServicos"
                      :reduce="val=>val.value"
                      :clearable="true"
                      placeholder="TODOS"
                    />
                  </b-form-group>
                  <span class="undermessage-error">
                    {{ validationContext.errors[ 0 ] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
              >
                <div class="pt-2">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mb-sm-0 mr-0 mr-sm-1"
                  >
                    Buscar
                    <feather-icon
                      class="ml-1 d-md-none d-xl-inline d-inline"
                      icon="SearchIcon"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
    <div 
      v-if="dados"
    >
      <b-row>
        <b-col 
          class="p-1"
        >
          <GraficoBarra
            :quantidade-de-obj-entregue="dados.quantidadeDeObjEntregue"
            :media-em-dias-da-entrega="dados.mediaEmDiasDaEntrega"
            :media-geral-em-dias="dados.mediaGeralEmDias"
            :uf="dados.uf"
          />
        </b-col>
      </b-row>
      <b-row style="justify-content: space-evenly;">
        <b-col 
          cols="6"
        >
          <b-card
            no-body
            class="py-1"
          >
            <GraficoPizza
              :series="dados.situacaoDosEnvios.series"
              :title="dados.situacaoDosEnvios.title"
              :labels="dados.situacaoDosEnvios.labels"
              :colors="dados.situacaoDosEnvios.colors"
            />
          </b-card>
        </b-col>
        <b-col 
          cols="6"
        >
          <b-card
            no-body
            class="py-1"
          >
            <GraficoPizza
              :series="dados.sitauacaoTratativas.series"
              :title="dados.sitauacaoTratativas.title"
              :labels="dados.sitauacaoTratativas.labels"
              :colors="dados.sitauacaoTratativas.colors"
            />
          </b-card>
        </b-col>
      </b-row>
    </div>
    
  </div>
</template>

<script>
import { reactive, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'
import moment from 'moment'
import store from '@/store'
import utils from '@/helpers/utils'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import DateRange from '@/components/form/DateRange.vue'
import GraficoBarra from './components/GraficoBarra.vue'
import GraficoPizza from './components/GraficoPizza.vue'

export default {
  name: 'OverviewEnvios',
  components: { GraficoBarra, GraficoPizza, DateRange },
  beforeMount() {
    const { clienteId } = JSON.parse(localStorage.getItem('userName'))
    this.$store.dispatch('departamento/findAll', clienteId)
    this.$store.dispatch('servicos/findAll', clienteId)
  },
  setup() {
    const { appCarregando } = $themeConfig.app
    const overviewEnviosStore = createNamespacedHelpers('overviewEnvios')

    const { clienteId } = JSON.parse(localStorage.getItem('userName'))

    const departamentoStore = createNamespacedHelpers('departamento')
    const servicoStore = createNamespacedHelpers('servicos')

    const state = reactive({
      departamentos: departamentoStore.useGetters(['departamentos']).departamentos,
      selectDepartamentos: [],
      mostrarDepartamento: false,
      servicos: servicoStore.useGetters(['servicos']).servicos,
      selectServicos: [],
      dateRange: {
        startDate: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        endDate: new Date().toISOString().split('T')[0],
      },
      filtros: {
        clienteDepartamentoId: [],
        servicoId: [],
        clienteId,
      },
      dados: overviewEnviosStore.useGetters(['dados']).dados,
      validationSearch: null,
    })

    watch(() => state.departamentos, value => {
      value.forEach(elemento => {
        state.selectDepartamentos.push({
          label: elemento.nomeDepartamento,
          value: elemento.clienteDepartamentoId,
        })
      })
    })

    watch(() => state.servicos, value => {
      value.forEach(elemento => {
        state.selectServicos.push({
          label: utils.serviceDictionary(elemento.nomeServico),
          value: elemento.nomeServico,
        })
      })
    })

    const reset = () => {
      state.filtros = {
        clienteDepartamentoId: [],
        servicoId: [],
        clienteId,
      }
    }
    const sendRequest = () => {
      const filtros = {
        ...state.filtros,
        dataInicial: state.dateRange.startDate, 
        dataFinal: state.dateRange.endDate,
      }
      appCarregando.show()
      store.dispatch('overviewEnvios/findAll', filtros).then(() => {
        appCarregando.hide()
      })
    }

    sendRequest()

    const onSubmit = () => {
      sendRequest()
    }
 
    const { getValidationState, resetForm } = formValidation(reset)

    return {
      ...toRefs(state),
      getValidationState,
      onSubmit,
      resetForm,
    }
  },
}
</script>
<style scoped>
.loading {
  top: 50%;
}
</style>