import EtiquetaService from '@/service/EtiquetaService'
import adapter from '@/adapters/EtiquetaAdapter'

export default {
  namespaced: true,
  state: {
    etiqueta: [],
  },
  getters: { 
    etiqueta(state) { 
      return state.etiqueta 
    },
  },
  mutations: {
    SAVE_ETIQUETA(state, etiquetas) {
      state.etiqueta.push(etiquetas)
    },
    UPDATE_SELECTED(state, etiquetas) {
      state.selected = etiquetas
    },
  },
  actions: {
    async save({ commit }, etiquetas) {
      return EtiquetaService.save(adapter.toApi(etiquetas))
        .then(etiquetaId => {
          const etiquetaToSave = { ...etiquetas, etiquetaId }
          commit('SAVE_ETIQUETA', etiquetaToSave)
          return etiquetaId
        })
    },
    async saveFinal({ commit }, etiquetas) {
      return EtiquetaService.saveFinal(adapter.toApi(etiquetas))
        .then(etiquetaId => {
          const etiquetaToSave = { ...etiquetas, etiquetaId }
          commit('SAVE_ETIQUETA', etiquetaToSave)
          return etiquetaId
        })
    },

    async savePorIntegracao({ commit }, { integracaoId, etiqueta }) {
      return EtiquetaService.savePorIntegracao(integracaoId, adapter.toApi(etiqueta))
        .then(etiquetaId => {
          const etiquetaToSave = { ...etiqueta, etiquetaId }
          commit('SAVE_ETIQUETA', etiquetaToSave)
          return etiquetaId
        })
    },

    async findById({ commit }, etiquetaId) {
      const etiquetas = await EtiquetaService.findById(etiquetaId)
      commit('UPDATE_SELECTED', adapter.toStore(etiquetas))
    },

    async delete(_, etiquetaEmailId) {
      await EtiquetaService.delete(etiquetaEmailId).then(data => data)
    },
  },
}
