import httpClient from './http-clients/portaljad'

const baseUrl = 'etiqueta'
const msgErro = 'Não foi possível enviar o pedido para a JADLOG. Verifique os dados do arquivo.'

export default class EtiquetaService {
  static async findAll() {
    try {
      const { data } = await httpClient.get(baseUrl)

      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(id) {
    try {
      const { data } = await httpClient.get([baseUrl, id]).join('/')
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async save(etiqueta) {
    return httpClient.post(baseUrl, etiqueta)
      .then(response => {
        if (response && response.data) {
          return response.data
        }
        throw Error(msgErro)
      })
      .catch(() => {
        throw Error(msgErro)
      })
  }

  static async saveFinal(etiqueta) {
    return httpClient.post([baseUrl, 'final'].join('/'), etiqueta)
      .then(response => {
        if (response && response.data) {
          return response.data
        }
        throw Error(msgErro)
      })
      .catch(() => {
        throw Error(msgErro)
      })
  }

  static async savePorIntegracao(integracaoId, etiqueta) {
    return httpClient.post([baseUrl, 'integracao', integracaoId].join('/'), etiqueta)
      .then(response => {
        if (response && response.data) {
          return response.data
        }
        throw Error(msgErro)
      })
      .catch(() => {
        throw Error(msgErro)
      })
  }

  static async delete(etiquetaEmailId) {
    try {
      await httpClient.delete([baseUrl, etiquetaEmailId].join('/'))
    } catch (error) {
      throw error.response.data
    }
  }
}