<template>
  <b-card
    no-body
    class="mb-2"
  >
    <b-card-header class="p-2">
      <b-card-title class="font-medium-2 card-title">
        <feather-icon
          icon="PackageIcon"
          size="18"
        />
        <span class="align-middle ml-50">
          Objetos
        </span>
      </b-card-title>
    </b-card-header>
    <hr class="my-0">
    <h3 class="p-2">
      Conteúdo
    </h3>
    <app-collapse
      accordion
      type="default"
    >
      <app-collapse-item
        :is-visible="!permitirDecConteudo"
        title="Tenho Nota Fiscal"
        class="pl-1"
      >
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="Nota Fiscal"
              rules="noAlpha"
            >
              <b-form-group
                label="Nota Fiscal"
                label-for="nota-fiscal"
              >
                <b-form-input
                  id="nota-fiscal"
                  v-model="objetos.numeroNotaFiscal"
                  v-cleave="cleaveOptions.notaFiscal"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="123"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Série da Nota Fiscal"
              rules="noAlpha"
            >
              <b-form-group
                label="Série da Nota Fiscal"
                label-for="serie-nota-fiscal"
              >
                <b-form-input
                  id="serie-nota-fiscal"
                  v-model="objetos.serieNotaFiscal"
                  v-cleave="cleaveOptions.serieNotaFiscal"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="123"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <validation-provider
              #default="validationContext"
              name="Chave da Nota Fiscal"
              rules="noAlpha"
            >
              <b-form-group
                label="Chave da Nota Fiscal"
                label-for="chave-nota-fiscal"
              >
                <b-form-input
                  id="chave-nota-fiscal"
                  v-model="objetos.chaveNotaFiscal"
                  v-cleave="cleaveOptions.chaveNotaFiscal"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="123"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Valor da Nota Fiscal"
              rules=""
            >
              <b-form-group
                label="Valor da Nota Fiscal"
                label-for="valor-nota-fiscal"
              >
                <CurrencyInput
                  v-model="objetos.valorNotaFiscal"
                  :show-symbol="true"
                  :required="false"
                  :validation-context="validationContext"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Data da Nota Fiscal"
            >
              <FormDatepicker
                id="dataNotaFiscal"
                ref="refDataNotaFiscal"
                v-model="objetos.dataNotaFiscal"
                label="Data da Nota Fiscal"
                :default-date="objetos.dataNotaFiscal"
                @update="(novaData) => objetos.dataNotaFiscal = novaData"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Nº Pedido"
            >
              <b-form-group
                label="Nº Pedido"
                label-for="numero-pedido"
              >
                <b-form-input
                  id="numeroPedido"
                  ref="refNumeroPedido"
                  v-model="localNumeroPedido"
                  :state="getValidationState(validationContext)"
                  trim
                  @input="updateNumeroPedido"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </app-collapse-item>
      <app-collapse-item
        v-if="permitirDecConteudo"
        title="Não tenho NF, preciso declarar o conteúdo"
        class="pl-1"
      >
        <validation-observer
          ref="conteudoValidation"
          #default="{handleSubmit}"
        >
          <b-form @submit.prevent="handleSubmit(addItem)">
            <b-card
              no-body
              class="mb-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Descricao do Item"
                    rules="required"
                  >
                    <b-form-group
                      label="Descricao do Item"
                      label-for="descricao-item"
                    >
                      <b-form-input
                        id="descricao-item"
                        v-model="item.descricao"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Piscina de bolinha"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Quantidade"
                    rules="noAlpha|required"
                    mode="lazy"
                  >
                    <b-form-group
                      label="Quantidade"
                      label-for="quantidade"
                    >
                      <b-form-input
                        id="quantidade"
                        v-model="item.quantidade"
                        :state="getValidationState(validationContext)"
                        placeholder="0"
                        @change="item.quantidade = $event, calcularValorTotal()"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Valor Unitário"
                    rules="required"
                    mode="lazy"
                  >
                    <b-form-group
                      label="Valor Unitário"
                      label-for="valorUnitario"
                    >
                      <CurrencyInput
                        v-model="item.valorUnitario"
                        :validation-context="validationContext"
                        :show-symbol="true"
                        @change="item.valorUnitario = $event, calcularValorTotal()"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group 
                    label="Valor Total"
                    label-for="valorTotal"
                  >
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text>
                          R$
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        id="valorTotal"
                        v-model="valorTotalFormatado"
                        readonly
                        placeholder="0,00"
                      />
                    </b-input-group>
                  </b-form-group> 
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                >
                  <div class="pt-2">
                    <b-button
                      variant="primary"
                      class="w-100 d-block"
                      type="submit"
                      style="max-height:38px;"
                    >
                      Adicionar
                      <feather-icon
                        class="ml-1 d-md-none d-xl-inline d-inline"
                        icon="PlusIcon"
                      />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-form>
        </validation-observer>
        <b-card
          no-body
          class="mb-0"
        >
          <b-table
            ref="refItensTable"
            class="position-relative"
            :items="itens"
            :fields="itensTableColumns"
            show-empty
            stacked="md"
            empty-text="Não há nenhum item declarado."
          >
            <template #cell(valorUnitario)="data">
              {{ formataValor(data) }}
            </template>
            <template #cell(valorTotal)="data">
              {{ formataValor(data) }}
            </template>
            <template #cell(excluir)="data">
              <a @click="removerItem(data)">
                <feather-icon
                  class="text-danger"
                  icon="TrashIcon"
                />
              </a>
            </template>
          </b-table>
        </b-card>
        <b-ro>
          <b-col
            cols="12"
            md="4"
            class="numero-declaracao"
          >
            <validation-provider
              #default="validationContext"
              name="Nº da Declaração"
            >
              <b-form-group
                label="Nº da Declaração"
                label-for="numero-declaracao"
                class=""
              >
                <b-form-input
                  id="numero-declaracao"
                  v-model="localNumeroDeclaracao"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="DEC[VALOR]"
                  @input="updateNumeroDeclaracao"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="numero-dec"
          >
            <validation-provider
              #default="validationContext"
              name="Nº Pedido"
            >
              <b-form-group
                label="Nº Pedido"
                label-for="numero-pedido"
              >
                <b-form-input
                  id="numeroPedido"
                  ref="refNumeroPedido"
                  v-model="localNumeroPedido"
                  :state="getValidationState(validationContext)"
                  trim
                  @input="updateNumeroPedido"
                />
    
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-ro>
      </app-collapse-item>
    </app-collapse>
    <hr class="my-0">
    <b-row>
      <b-col md="9">
        <h3 class="p-2">
          Volumes
        </h3>
      </b-col>
      <b-col md="3">
        <h3 class="p-2">
          <b-form-checkbox
            id="manterPeso"
            v-model="manterPeso"
          >
            Manter peso
          </b-form-checkbox>
          <b-form-checkbox
            id="manterMedidas"
            v-model="manterMedidas"
          >
            Manter medidas
          </b-form-checkbox>
        </h3>
      </b-col>
    </b-row>
    <b-card
      no-body
      class="mb-2"
      style="padding-left:28px; padding-right: 14px"
    >
      <validation-observer
        ref="volumeValidation"
        #default="{handleSubmit}"
      >
        <b-form
          @submit.prevent="handleSubmit(addVolume)"
          @reset.prevent="resetVolume()"
        >
          <b-row>
            <b-col
              cols="12"
              md="2"
            >
              <validation-provider
                #default="validationContext"
                name="Peso"
                rules="required"
              >
                <b-form-group
                  label="Peso(kg)"
                  label-for="peso"
                >
                  <CurrencyInput
                    v-model="volume.pesoEmQuilo"
                    :show-symbol="false"
                    :required="true"
                    :validation-context="validationContext"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <validation-provider
                #default="validationContext"
                name="Formato"
                rules="required"
              >
                <b-form-group
                  label="Formato"
                  label-for="formato"
                >
                  <v-select
                    v-model="volume.formato"
                    :options="formatoOptions"
                    :reduce="val=>val.value"
                    :clearable="false"
                  />
                  <span class="undermessage-error">
                    {{ validationContext.errors[0] }}
                  </span>
                </b-form-group>
              </validation-provider>
            </b-col>
            <template v-if="volume.formato==='PACOTE' || volume.formato==='ENVELOPE'">
              <b-col
                cols="12"
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  name="Altura"
                  rules="integer|required"
                >
                  <b-form-group
                    label="Altura(cm)"
                    label-for="altura"
                  >
                    <b-form-input
                      id="altura"
                      v-model="volume.altura"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="100"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </template>
            <template v-if="volume.formato==='PACOTE' || volume.formato==='ENVELOPE'">
              <b-col
                cols="12"
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  name="Largura"
                  rules="integer|required"
                >
                  <b-form-group
                    label="Largura(cm)"
                    label-for="largura"
                  >
                    <b-form-input
                      id="largura"
                      v-model="volume.largura"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="100"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </template>
            <b-col
              cols="12"
              md="2"
            >
              <validation-provider
                #default="validationContext"
                name="Comprimento"
                rules="integer|required"
              >
                <b-form-group
                  label="Comprimento(cm)"
                  label-for="comprimento"
                >
                  <b-form-input
                    id="comprimento"
                    v-model="volume.comprimento"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="100"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <template v-if="volume.formato==='CILINDRO'">
              <b-col
                cols="12"
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  name="Diâmetro"
                  rules="integer|required"
                >
                  <b-form-group
                    label="Diâmetro(cm)"
                    label-for="diametro"
                  >
                    <b-form-input
                      id="diametro"
                      v-model="volume.diametro"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="100"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </template>
            <b-col
              cols="12"
              md="2"
            >
              <div class="pt-2">
                <b-button
                  variant="primary"
                  class="w-100 d-block"
                  type="submit"
                  style="max-height:38px;"
                >
                  Adicionar
                  <feather-icon
                    class="ml-1 d-md-none d-xl-inline d-inline"
                    icon="PlusIcon"
                  />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-table
        ref="refVolumesTable"
        class="position-relative"
        :items="volumes"
        :fields="volumesTableColumns"
        show-empty
        stacked="md"
        empty-text="Não há nenhum volume declarado."
      >
        <template #cell(excluir)="data">
          <a @click="removerVolume(data)">
            <feather-icon
              class="text-danger"
              icon="TrashIcon"
            />
          </a>
        </template>
      </b-table>
    </b-card>
    <hr class="my-0">
    <h3 class="p-2">
      Serviço
    </h3>
    <b-card
      no-body
      class="mb-2"
      style="padding-left:28px; padding-right: 14px"
    >
      <b-card-text>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Serviço"
            >
              <b-form-group
                label-for="servico"
                label="Serviço"
              >
                <v-select
                  v-model="objetos.servicoId"
                  :options="servicosOptions"
                  :reduce="val=>val.value"
                  :clearable="false"
                  @input="(value) => objetos.nomeServico = nomeServico(value)"
                />
                <span class="undermessage-error">
                  {{ validationContext.errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Observação"
            >
              <b-form-group
                label-for="observacao"
                label="Observação"
              >
                <b-form-input
                  id="observacao"
                  v-model="objetos.observacao"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <span class="undermessage-error">
                  {{ validationContext.errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <hr class="my-0">
    <div class="p-2">

      <b-row class="flex-row-reverse">
        <div style="display:flex; align-items: center; justify-content: center">
          <b-button
            variant="primary"
            class="mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
          >
            Concluir
            <feather-icon icon="CheckIcon" />
          </b-button>
        </div>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import cleaveOptions from '@/libs/cleave/options'
import { watch, ref, reactive, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { mapGetters } from 'vuex'
import FormDatepicker from '@/components/form/FormDatepicker.vue'
import CurrencyInput from '@/components/form/CurrencyInput'

export default {
  name: 'Objetos',
  components: {
    FormDatepicker,
    CurrencyInput,
  },
  props: {
    getValidationState: {
      type: Function,
      required: true,
    },
    objetos: {
      type: undefined,
      required: true,
    },
    numeroDeclaracao: {
      type: String,
      required: false,
      default: '',
    },
    numeroPedido: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      valorTotal: 0,
    }
  },
  computed: {
    ...mapGetters({
      firebaseUser: 'firebase/firebaseUser',
    }),
    valorTotalFormatado() {
      return this.valorTotal ? this.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '') : '0,00'
    },
  },
  watch: {
    item() {
      if (!this.item.quantidade && !this.item.valorUnitario) {
        this.valorTotal = null
      }
    },
  },
  beforeMount() {
    const { clienteId } = JSON.parse(localStorage.getItem('userName'))
    const tipo = 'reversa'
    this.$store.dispatch('servicos/findAllTipo', { clienteId, tipo })
  },
  methods: {
    formataValor({ value }) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    calcularValorTotal() {
      if (this.item.quantidade && this.item.valorUnitario) {
        this.item.valorTotal = Number(this.item.quantidade) * Number(this.item.valorUnitario)
        this.valorTotal = Number(this.item.quantidade) * Number(this.item.valorUnitario)
      }
    },
  },
  setup(props, context) {
    const manterMedidas = ref(true)
    const manterPeso = ref(true)
    const servicoStore = createNamespacedHelpers('servicos')
    const { permitirEnvioDeclaracaoConteudo } = JSON.parse(localStorage.getItem('userName'))

    const state = reactive({
      servicoGetters: servicoStore.useGetters(['servicos']).servicos,
      servicosOptions: [],
      permitirDecConteudo: permitirEnvioDeclaracaoConteudo,
    })
    const conteudoValidation = ref(null)
    const refItensTable = ref(null)

    const volumeValidation = ref(null)
    const itens = ref(props.objetos.conteudo)
    const item = ref({})

    watch(
      () => state.servicoGetters,
      value => {
        value.forEach(elemento => {
          state.servicosOptions.push({
            label: elemento.nomeServico,
            value: elemento.id,
          })
        })
      },
    )

    const localNumeroDeclaracao = ref(props.numeroDeclaracao)

    const updateNumeroDeclaracao = () => {
      context.emit('update:numeroDeclaracao', localNumeroDeclaracao.value)
    }

    watch(() => props.numeroDeclaracao, value => {
      localNumeroDeclaracao.value = value
    })

    const localNumeroPedido = ref(props.numeroPedido)

    const updateNumeroPedido = () => {
      context.emit('update:numeroPedido', localNumeroPedido.value)
    }

    watch(() => props.numeroPedido, value => {
      localNumeroPedido.value = value
    })

    const nomeServico = id => {
      const { label } = state.servicosOptions.find(s => s.value === id)
      return label
    }

    const removerItem = data => {
      itens.value.splice(data.index, 1)
    }

    const resetItem = () => {
      item.value = {}
      conteudoValidation.value.reset()
    }

    const somaTotal = cont => {
      const { objetos } = props
      const data = cont.map(c => c.valorTotal).reduce((a, valor) => a + valor)
      objetos.somaTotal = data
      context.emit('update:objetos', objetos)
      return data
    }

    const addItem = () => {
      const { objetos } = props
      objetos.conteudo.push(item.value)
      somaTotal(objetos.conteudo)
      context.emit('update:objetos', objetos)
      resetItem()
    }

    const itensTableColumns = [
      { key: 'descricao', sortable: true, label: 'Descrição' },
      { key: 'quantidade', sortable: true },
      { key: 'valorUnitario', sortable: true, label: 'Valor Unitário' },
      { key: 'valorTotal', sortable: true, label: 'Valor Total' },
      { key: 'excluir', sortable: false },
    ]
    const volumes = ref(props.objetos.volumes)

    const volume = ref({
      formato: 'PACOTE',
    })

    const removerVolume = data => {
      volumes.value.splice(data.index, 1)
    }

    const resetVolume = () => {
      if (!manterPeso.value && !manterMedidas.value) {
        volume.value = { formato: 'PACOTE' }
        volumeValidation.value.reset()
      } 
      if (manterPeso.value && !manterMedidas.value) {
        volume.value = {
          formato: volume.value.formato,
          peso: volume.value.peso,
        }
      }
      if (!manterPeso.value && manterMedidas.value) {
        volume.value = {
          formato: volume.value.formato,
          altura: volume.value.formato !== 'CILINDRO' ? volume.value.altura : null,
          largura: volume.value.formato !== 'CILINDRO' ? volume.value.largura : null,
          diametro: volume.value.formato === 'CILINDRO' ? volume.value.diametro : null,
          comprimento: volume.value.comprimento,
        }
      }
    }

    const addVolume = () => {
      const { objetos } = props
      const vol = volume.value
      objetos.volumes.push({ ...vol })
      context.emit('update:objetos', objetos)
      resetVolume()
    }

    const refVolumesTable = ref(null)

    const volumesTableColumns = [
      { key: 'pesoEmQuilo', sortable: true, label: 'Peso' },
      { key: 'formato', sortable: true },
      { key: 'altura', sortable: true },
      { key: 'largura', sortable: true },
      { key: 'comprimento', sortable: true },
      { key: 'diametro', sortable: true, label: 'diâmetro' },
      { key: 'excluir', sortable: false },
    ]

    const formatoOptions = [
      { label: 'PACOTE', value: 'PACOTE' },
      { label: 'CILINDRO', value: 'CILINDRO' },
      { label: 'ENVELOPE', value: 'ENVELOPE' },
    ]

    const refDataNotaFiscal = ref(null)

    return {
      ...toRefs(state),
      manterMedidas,
      manterPeso,
      cleaveOptions,
      conteudoValidation,
      refItensTable,
      volumeValidation,
      item,
      itens,
      removerItem,
      addItem,
      itensTableColumns,
      volume,
      volumes,
      formatoOptions,
      removerVolume,
      addVolume,
      refVolumesTable,
      volumesTableColumns,
      nomeServico,
      refDataNotaFiscal,

      localNumeroDeclaracao,
      updateNumeroDeclaracao,
      localNumeroPedido,
      updateNumeroPedido,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.numero-doc {
  margin-top: 1.5em
}
</style>