<template>
  <vue-apex-charts
    :options="options"
    :series="options.series"
    class="chart"
    height="380"
  />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'GraficoBarra',
  components: { VueApexCharts },
  props: {
    series: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const options = {
      series: props.series,
      labels: props.labels,
      colors: props.colors,
      tooltip: {
        enabled: true,
        custom: ({ series, seriesIndex, w }) => {
          const label = w.globals.labels[seriesIndex]
          const quantidade = series[seriesIndex]
          const percent = w.globals.seriesPercent[seriesIndex][0].toFixed(1)
          return `<b-badge class="p-1">${label} quantidade: ${quantidade} envios (${percent}%)</b-badge>`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
        donut: {
          labels: {
            show: true,
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: 'bottom',
        formatter: (val, opts) => {
          const legenda = `${opts.w.globals.labels[opts.seriesIndex]}: ${opts.w.globals.initialSeries[opts.seriesIndex]} Envios (${opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(1)}%)`
          return legenda
        },
      },
      dataLabels: {
        enabled: false,
      },
      noData: {
        text: 'Nenhum resultado',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined,
        },
      },
      chart: {
        type: 'donut',
      },
      title: {
        text: props.title,
        align: 'center',
        margin: 25,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '16px',
          fontWeight: 600,
          color: '#5e5873',
        },
      },
    }
    return {
      options,
    }
  },
}
</script>

<style>
.chart {
  display: inline-block;
}

</style>